import React from "react";
import SEO from "../components/seo";
import FunctionPageLayout from "../components/functionPageLayout";
import ResponsiveEmbed from "react-responsive-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";
import { graphql, useStaticQuery, Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import { Box, Flex, Stack, Text } from "@chakra-ui/core";
import {
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/core";

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      abLogo2: file(relativePath: { eq: "abLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <FunctionPageLayout
      title="Zarządzanie firmą z systemami CRM, B2B i Service Management"
      headline="Praktyczny vlog o tym, jak wyciągnąć maksimum z systemów do zarządzania"
    >
      <SEO
        title="Zarządzanie firmą z systemami CRM, B2B i Service Management"
        description="Zarządzanie firmą z systemami CRM, B2B i Service Management ✅ Porady, wskazówki i podpowiedzi • Questy - dostawcy oprogramowania dla biznesu"
        keywords={[
          "Zarządzanie firmą z systemami CRM, B2B i Service Management",
        ]}
      />

      <Container pt="30px">
          <Tabs size="lg" align="center" variantColor="green" maxW="full">
            <TabList color="#ffffff" fontSize="16px">
              <Tab textTransform="uppercase">Wszystkie odcinki</Tab>
              
              <Tab ml={{ base: 0, lg: 8 }} textTransform="uppercase">
                Zarządzanie sprzedażą
              </Tab>
              <Tab ml={{ base: 0, lg: 8 }} textTransform="uppercase">
                Zarządzanie serwisem
              </Tab>
              <Tab ml={{ base: 0, lg: 8 }} textTransform="uppercase">
                Sprzedaż hurtowa
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <TabAll data={data}></TabAll>
              </TabPanel>
              <TabPanel>
                <TabCrm data={data}></TabCrm>
              </TabPanel>
              <TabPanel>
                <TabSerwis data={data}></TabSerwis>
              </TabPanel>
              <TabPanel>
                <TabB2B data={data}></TabB2B>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
        


    </FunctionPageLayout>
  );
};

  const TabAll = ({ data }) => {
  return (

<Stack
      css={css`
        div ~ div {
          border-top: 0px solid #e1e1e1;
        }
      `}
      align="center"
    >

<Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
               Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta
              </strong>
            </p>
            <p>
           Realizujesz działania w&nbsp;terenie? Doskwiera Ci ciągły brak aktualnych 
           i&nbsp;szczegółowych informacji o&nbsp;pracach koniecznych do wykonania? Chcesz 
           działać szybciej i&nbsp;efektywniej? Może to czas na wdrożenie funkcjonalnego 
           narzędzia informatycznego? Poznaj program do serwisu z&nbsp;wbudowaną aplikacją 
           do obsługi zleceń i&nbsp;ciesz się automatyzacją pracy! 
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/aplikacja-do-obslugi-zlecen/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="qHrBm6zsZ24"
              title="Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta"
            />
          </div>
        </div>
      </Container>

     <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Automatyczny raport pracy serwisowej. Zarządzaj serwisem w&nbsp;jednym narzędziu.
              </strong>
            </p>
            <p>
            Chcesz monitorować efektywność Twojego zespołu i&nbsp;prowadzonych przez 
            niego działań? Nie chcesz poświęcać zbyt dużej ilości czasu na proces 
            raportowania? Potrzebujesz narzędzia, które w kompleksowy sposób wesprze 
            Twoją firmę? Zapoznaj się z&nbsp;naszym materiałem i&nbsp;sprawdź, w&nbsp;jaki 
            sposób może pomóc Ci w&nbsp;tym wszystkim program do serwisu.
        
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/raport-pracy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="cDEwQhAXk58"
              title="Raportowanie pracy w programie serwisowym. Jak usprawni Twoją pracę?"
            />
          </div>
        </div>
      </Container>

    <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Dlaczego warto łączyć moduł serwisowy z&nbsp;systemem CRM?
              </strong>
            </p>
            <p>
            Wyzwaniem jest dla Ciebie współpraca Twoich zespołów – sprzedażowego 
            i&nbsp;serwisowego? Zastanawiasz się jakich narzędzi informatycznych 
            dostarczyć im, aby praca przebiegała sprawniej i&nbsp;efektywniej? 
            Połącz moduł serwisowy z&nbsp;systemem CRM i&nbsp;w&nbsp;jednym 
            rozwiązaniu zarządzaj zleceniami oraz relacjami z&nbsp;klientami. 
            Obejrzyj nasz vlog i&nbsp;dowiedz się, jakie konkretne korzyści 
            uzyskasz dzięki integracji.          
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/modul-serwisowy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="OTiwe-m4Zfw"
              title="Dlaczego WARTO łączyć CRM z systemem do obsługi ZGŁOSZEŃ?"
            />
          </div>
        </div>
      </Container>

<Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?
              </strong>
            </p>
            <p>
            Jesteś menedżerem sprzedaży? Zarządzasz zespołem handlowców? Zajmujesz się wieloma różnymi zadaniami?
            Szukasz sposobu na poprawę efektywności swojej pracy? Poznaj CRM i&nbsp;dowiedz się, jak to narzędzie
            może pomóc w&nbsp;realizacji Twoich codziennych obowiązków. Znajdź przepis na sukces.          
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/wsparcie-sprzedazy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="Tqg5oPl9jp4"
              title="JAK KORZYSTAĆ z CRM będąc managerem sprzedaży?"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Integracja systemów ERP z&nbsp;programem do obsługi zleceń
              </strong>
            </p>
            <p>
              Zarządzanie zleceniami to Twoja codzienność? Posiadasz już ERP,
              a&nbsp;teraz poszukujesz programu do obsługi procesów serwisowych? 
              Dowiedz się dlaczego integracja systemu handlowo-magazynowego
              z&nbsp;programem serwisowym jest tak istotna i&nbsp;co możesz dzięki niej 
              zyskać.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/integracja-systemow-erp/"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="UdfDXz3yRiY"
            title="Integracja programu do OBSŁUGI SERWISU z systemem ERP" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Obsługa reklamacji online – droga do efektywności
              </strong>
            </p>
            <p>
              Zajmujesz się reklamacjami i&nbsp;chciałbyś ulepszyć prowadzone działania?
              Zastanawiasz się na rozwiązaniami informatycznymi, które mogłyby wspomóc Cię 
              w&nbsp;pracy? Zapoznaj się z&nbsp;materiałem i&nbsp;poznaj system do obsługi reklamacji online.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/obsluga-reklamacji-online/"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="WKUJ_7FBVtU"
            title="Skuteczna obsługa reklamacji" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
      <strong>
              Wdrożenie panelu B2B kluczem do usprawnienia procesu sprzedaży
              </strong>
            </p>
            <p>
             Zastanawiasz się nad wdrożeniem panelu B2B, ale masz wątpliwości?
             Opowiemy Ci dzisiaj o&nbsp;zaletach tego rozwiązania oraz pokażemy, 
             że&nbsp;panel B2B jest narzędziem, które pomoże Ci jednocześnie budować 
             relacje z&nbsp;klientami i&nbsp;usprawniać proces sprzedażowy.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/wdrozenie-panelu-b2b/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="MErq_-FEF7w"
              title="Korzyści z wdrożenia panelu B2B - po co wykorzystywać platformę?"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Wykorzystaj program do tworzenia ofert handlowych w&nbsp;celu poprawy swoich działań sprzedażowych
              </strong>
            </p>
            <p>
            Pracujesz jako przedstawiciel handlowy i&nbsp;chciałbyś poprawić wyniki swojej pracy? Chcesz realizować targety szybciej i&nbsp;efektywniej? 
            Wykorzystaj program do&nbsp;tworzenia ofert handlowych. Nasz doświadczony handlowiec opowie Ci dzisiaj o&nbsp;systemie
            CRM, który stosuje w&nbsp;swojej pracy. Dowiesz się o&nbsp;korzyściach, które wynikają z&nbsp;jego wdrożenia.            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/program-do-tworzenia-ofert-handlowych/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="TYql_R1ca_A"
              title="CRM dla handlowców. Jak pomoże Ci w pracy?"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
             
              <strong>
               Promowanie produktów w platformie sprzedażowej B2B
              </strong>
            </p>
            <p>
             Promowanie produktów w platformie sprzedażowej B2B jest nie tylko świetnym sposobem
             na zwiększenie sprzedaży, ale też pozwala skutecznie budować relacje biznesowe z&nbsp;kontrahentami.
              Zapoznaj się z&nbsp;tym materiałem i poznaj skuteczne metody wyróżniania Twoich produktów, które przełożą
              się na relacje Twojej firmy z klientami B2B.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/promowanie-b2b/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="5vQdn_M_SBU"
              title="Niezawodne sposoby na promocję produktów w platformie sprzedażowej B2B"
            />
          </div>
        </div>
      </Container>
      
      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Dashboard managera – jeden rzut oka, by&nbsp;poznać sytuację całej firmy
              </strong>
            </p>
            <p>
             Jako manager z pewnością każdego dnia podejmujesz decyzje, od których zależy rozwój firmy. 
             Używasz do tego kryształowej kuli, czy&nbsp;też opierasz się na twardych danych i&nbsp;informacjach?
             W tym materiale dowiesz się czym jest dashboard managera, dlaczego jest tak popularny oraz jak go przygotować
             nie tracąc przy tym całego dnia.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/dashboard-managera/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="WJTMIzEs71I"
              title="Wykorzystaj dashboard managera, by natychmiast poznać sytuację w całym przedsiębiorstwie"
            />
          </div>
        </div>
      </Container>

            <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                To ułatwi pracę w Twoich projektach IT
              </strong>
            </p>
            <p>
             Poznaj narzędzia, które wspierają pracę przedsiębiorstw z branży IT. W&nbsp;tym&nbsp;odcinku przedstawiamy
              sposób na efektywne zarządzanie sprzedażą oraz&nbsp;usługami na podstawie elementów, które na co dzień
              wykorzystujemy w&nbsp;Questy.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/crm-dla-firmy-it/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}>
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="eiY7L3TRmME"
              title="To UŁATWI pracę w Twoich projektach IT"
            />
          </div>
        </div>
      </Container>

             <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Proces sprzedaży: To musisz o nim wiedzieć
              </strong>
            </p>
            <p>
              We współczesnych przedsiębiorstwach niezwykle ważną rolę odgrywają 
              narzędzia pozwalające na sprawne wsparcie procesu sprzedaży. Sprawdź, jak
              po mistrzowsku możesz zaprojektować swój własny proces sprzedaży.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/proces-sprzedazy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="_zpw8pqTHqk"
              title="Jak dobrze zaprojektować proces sprzedaży?"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Jak praca w systemie do obsługi zleceń usprawnia działanie
                firmy?
              </strong>
            </p>
            <p>
              Dlaczego obsługa zleceń jest prostsza przy wykorzystaniu
              dedykowanych do tego rozwiązań? W jaki sposób program do serwisu
              wspiera kierownikow i&nbsp;serwisantów w&nbsp;codziennej pracy? Obejrzyj
              materiał, aby&nbsp;poznać odpowiedzi.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/system-do-obslugi-zgłoszen-serwisowych/"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="Pdvju_IiM2Y"
            title="CRM do obsługi zgłoszeń serwisowych" />
          </div>
        </div>
      </Container>


            <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Jak zwiększyć efektywność dzięki automatyzacji sprzedaży?
              </strong>
            </p>
            <p>
              Czujesz, że tracicie czas na wykonanie prostych i powtarzalnych
              czynności? Sprawdź, jak automatyzacja wpływa na efektywność
              zespołu sprzedaży.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/automatyzacja-sprzedazy">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="PEMvVqP7-Ao"
              title="Jak zwiększyć efektywność dzięki automatyzacji sprzedaży?"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Nieobecność pracownika – jak zapewnić ciągłość pracy?</strong>
            </p>
            <p>
             Skuteczne zastąpienie pracowników firmy podczas ich nieobecności jest współcześnie
             wyzwaniem dla wielu przedsiębiorstw. Jak się odpowiednio na to przygotować?
            </p>

            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/zastepowalnosc-pracownikow">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="yQ6BSlB2JB0"
              title="Jak przygotować się na nieobecność pracownika?"
            />
          </div>
        </div>
      </Container>


      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Jak wyznaczać cele sprzedażowe?</strong>
            </p>
            <p>
              Określanie celów sprzedażowych to trudne zadanie. Co zrobić, aby
              cel był&nbsp;motywacją? O&nbsp;planach sprzedażowych i roli systemu CRM
              dowiesz się z&nbsp;tego odcinka.
            </p>

            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/cele-sprzedazowe-smart">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="rfOKAeNxzho"
              title="Cele sprzedażowe - jak je określać?"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Narzędzia CRM online do pracy zdalnej</strong>
            </p>
            <p>
              W kolejnym odcinku z vloga z serii "System CRM w zarządzaniu"
              omawiamy temat zdalnej pracy. Poznasz w nim sposoby na radzenie
              sobie z&nbsp;problemami, jakie generuje, a także jak CRM online wspiera
              firmy w pracy na&nbsp;odległość.{" "}
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/narzedzia-crm-online">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="qE_DnhPfvCs"
            title="CRM online do pracy zdalnej" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Raportowanie sprzedaży: Excel to&nbsp;ostateczność, czyli jak
                błyskawicznie raporotwać w CRM
              </strong>
            </p>
            <p>
              Z kolejnego odcinka naszego vloga na temat zarządzania firmą w CRM
              dowiesz się, jak rozbudowane narzędzia do analizy danych wspierają
              błyskawiczne raportowanie sprzedaży.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/raportowanie-sprzedazy">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
            <div className="text-block" style={{
                padding: "30px",
              }}
          >
             <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="OvD2Rdt2Klk"
            title="Jak skutecznie raportować sprzedaż?" />

            </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Integracja B2B z ERP: dlaczego warto?</strong>
            </p>
            <p>
              Zapraszamy na trzeci odcinek serii "Sprzedaż online z platformą
              B2B" – vloga, na którym wyjaśniamy, jak odnieść sukces w hurtowej
              sprzedaży online. W tym materiale Piotr Szudrzyński, Business
              Development Manager w Questy, wyjaśnia dlaczego warto zintegrować
              platformę B2B z Twoim systemem ERP.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/b2b-dla-erp/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            
          <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="sS8OF33PvTU"
            title="Integracja B2B z ERP" />
          </div>
        </div>
      </Container>

        <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              <strong>5 etapów wdrożenia systemu B2B w&nbsp;organizacji</strong>
            </p>
            <p>
              Wdrożenie platformy B2B można podzielić na kilka etapów. Marta
              Kaźmierska, BDM Questy, omówi pięć kluczowych kroków skutecznego
              wdrożenia platformy sprzedażowej.
              <ol>
                <li>Wizja biznesowa</li>
                <li>Przygotowanie platformy</li>
                <li>Szkolenie pracowników</li>
                <li>Wdrożenie klienta</li>
                <li>Analiza, testowanie i doskonalenie</li>
              </ol>
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/wdrozenie-platformy-b2b">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="HnRLj0RH4DI"
            title="Wdrożenie B2B 5 kroków" />
          </div>
        </div>
      </Container>

        <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                System B2B – przeznaczenie i&nbsp;najważniejsze funkcje
              </strong>
            </p>
            <p>
              Zapraszamy na drugą odsłonę vloga "Sprzedaż online z platformą
              B2B" – vloga, na którym wyjaśniamy, jak odnieść sukces w hurtowej
              sprzedaży online. W niniejszym materiale Aleksandra Sobczyńska,
              Business Development Coordinator w Questy, opowie o&nbsp;tym, w jakich
              firmach systemy B2B spisują się najlepiej. Przedstawi też listę
              dziesięciu najważniejszych funkcji w tego typu oprogramowaniu.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/system-b2b-funkcje">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="FjqEsztUNaA"
            title="Przeznaczenie systemu B2B i jego funkcje" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Czym jest platforma B2B?</strong>
            </p>
            <p>
              Witamy serdecznie w pierwszym materiale na naszym nowym vlogu.
              Będziemy się na nim zajmować tematyką sprzedaży online. Na&nbsp;pierwszy 
              ogień idzie rzecz zupełnie podstawowa - odpowiedź na
              pytanie “Czym jest platforma B2B?” Oprócz wyjaśnienia tej kwestii,
              powiemy też kilka słów o&nbsp;tym, co dobrze jest wiedzieć przed
              inwestycją w jedną z takich platform.{" "}
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/czym-jest-platforma-b2b">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
           <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="P04WxsFvuE8"
            title="Platforma B2B co to jest?" />
          </div>
        </div>
      </Container>

           <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Zarządzaj historią urządzeń swoich klientów</strong>
            </p>
            <p>
              Trzeci odcinek z serii "Zarządzanie serwisem online" prezentuje
              Aleksandra Sobczyńska. Dowiesz się, jak obniżyć koszty obsługi
              zleceń, poprzez sprawne zarządzanie historią serwisowanych
              urządzeń Twoich klientów.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/baza-urzadzen/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="BweIoQaKSNg"
            title="Historia urządzeń w serwisie" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>7 problemów Twojego serwisu – jak je rozwiązać?</strong>
            </p>
            <p>
              Drugi odcinek naszej serii "Zarządzanie serwisem online" prowadzi
              Wojciech Nosal, Business Development Manager w Questy. Dowiesz się
              między innymi:
              <ul>
                <li>jak uporządkować pracę serwisu</li>
                <li>jak zautomatyzować procesy</li>
                <li>jak usprawnić komunikację w serwisie</li>
              </ul>
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/7-wyzwan-w-serwisie/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="BNmPHQuxvA0"
            title="Główne problemy serwisu - jak je rozwiązać?" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Jak zarządzać przeglądami cyklicznymi</strong>
            </p>
            <p>
              Zapraszamy na pierwszy odcinek serii "Zarządzanie serwisem online"
              – vloga, w&nbsp;którym wyjaśniamy, jak program do serwisu może wspomóc
              organizację pracy serwisu. W&nbsp;tym materiale Marta Kaźmierska,
              Business Development Manager w Questy, wyjaśnia jak ułatwić
              zarządzanie cyklicznymi zleceniami w&nbsp;serwisie.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/przeglady-cykliczne/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="TjlbbeCC6Xo"
            title="Jak zarządzać przeglądami cyklicznymi w serwisie" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Integracja CRM z systemem handlowym</strong>
            </p>
            <p>
              Tym razem na naszym vlogu o zarządzaniu firmą przy użyciu systemu
              CRM – temat integracji tego rozwiązania z programami
              handlowo-magazynowymi. Piotr Szudrzyński, Business Development
              Manager w&nbsp;Questy, przedstawia, jakie informacje przekazywane są
              pomiędzy systemami, jak często i&nbsp;co&nbsp;dobrego z&nbsp;tego wynika.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/integracja-crm-z-systemem-handlowym"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="a1S10rsTsb0"
            title="Integracja CRM z ERP" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Rozbudowa kanału sprzedaży pośredniej za&nbsp;pomocą systemu CRM
              </strong>
            </p>
            <p>
              Kolejny odcinek naszego vloga o zarządzaniu poprowadzi dla Was
              Marta Kaźmierska, Business Development Manager w Questy. Tym&nbsp;razem
              tematem materiału jest sprzedaż pośrednia oraz to, jak w jej
              organizacji oraz&nbsp;optymalizacji może wspomóc Twoją firmę system
              CRM.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/rozbudowa-kanalu-sprzedazy-posredniej"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="26Meh64YxP4"
            title="CRM w sprzedaży pośredniej" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Aplikacja CRM: ułatwiona praca w terenie</strong>
            </p>
            <p>
              Witamy w kolejnym wpisie na naszym vlogu o&nbsp;efektywnym zarządzaniu
              firmą za pomocą systemu CRM. Tym razem Wojciech Nosal, Business
              Development Manager w Questy, przedstawi Wam, jakie są opcje
              zdalnej pracy z&nbsp;naszym oprogramowaniem i jak ułatwić sobie
              korzystanie z CRM na&nbsp;urządzeniach przenośnych.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/aplikacja-crm-do-pracy-w-terenie"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="7eiEBXoOiNA"
            title="CRM online do pracy zdalnej" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Zalety elastycznego systemu CRM</strong>
            </p>
            <p>
              To już jedenasty odcinek naszego vloga o&nbsp;zarządzaniu firmą za
              pomocą systemu CRM. Tym razem Joanna Kujawska, Marketing Manager w
              Questy, pochyla się nad tematem elastyczności systemów
              wspomagających zarządzanie relacjami z klientami. Zapraszamy!
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/zalety-elastycznego-crm">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
           <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="HV3OYUaHOCk"
            title="Elastyczny CRM zalety" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Ofertowanie w CRM: jak zarządzać tym procesem?</strong>
            </p>
            <p>
              W najnowszej odsłonie naszego vloga o&nbsp;zarządzaniu firmą za pomocą
              systemu CRM, poruszamy temat ofertowania. Sprawdź, w jaki sposób
              nasz system pomaga uniknąć najczęściej występujących błędów w tym
              procesie.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/ofertowanie-crm-jak-zarzadzac"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
           <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="a_KLMaAYdRc"
            title="Ofertowanie w CRM proces" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Zarządzanie kampaniami, CRM i szanse sprzedaży</strong>
            </p>
            <p>
              Czym są kampanie sprzedaży i jak się mają do&nbsp;szans sprzedaży? Jak
              nimi zarządzać w&nbsp;systemie CRM? O kampaniach i szansach opowie tym
              razem Piotr Szudrzyński, Business Development Manager w Questy.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/zarzadzanie-kampaniami-crm-szanse-sprzedazy"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="LLvCdR5OL-I"
            title="Kampanie i szanse sprzedaży w CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>System CRM a miękka windykacja</strong>
            </p>
            <p>
              To już dziewiąty odcinek naszego vloga o&nbsp;zarządzaniu firmą za
              pomocą systemu CRM. Tym razem poruszamy temat miękkiej windykacji
              w CRM i&nbsp;tego, jak wykorzystać jego funkcje do usprawnienia
              procesów ściągania zaległych należności.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/system-crm-a-miekka-windykacja"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
             <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="w5HmaTIlNnU"
            title="Skuteczna windykacja w CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Najpopularniejszych 5 funkcji w systemach CRM dla firm – RANKING
                2020
              </strong>
            </p>
            <p>
              W kolejnym odcinku naszego vloga o&nbsp;zarządzaniu firmą za pomocą
              CRM, Tomasz Wawrzyński, Marketing Specialist w Questy, przedstawia
              ranking pięciu najpopularniejszych funkcji w systemie.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/ranking-crm-dla-firm-funkcje"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
             <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="LUioSzlrkRw"
            title="Top 5 funkcji CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>5 dobrych praktyk budowania relacji z&nbsp;klientami</strong>
            </p>
            <p>
              Budowanie relacji z klientami jest niezwykle istotnym aspektem
              pracy każdego handlowca – a przynajmniej powinno nim być. W
              kolejnym odcinku naszego vloga opowiadamy, dlaczego tak jest i jak
              w budowaniu trwałych relacji z&nbsp;klientami może pomóc system CRM.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/piec-dobrych-praktyk-budowania-relacji-z-klientami"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
             <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="UnCMKaoS5H0"
            title="Praktyki budowania relacji z klientami" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Lejek sprzedaży – wsparcie dla działu handlowego</strong>
            </p>
            <p>
              W materiale tym opowiadamy, czym jest lejek sprzedaży, jak się go
              tworzy i&nbsp;jakie korzyści może przynieść firmie. Pokazujemy
              też, jak pracować z&nbsp;lejkiem w systemie CRM.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/lejek-sprzedazy-crm">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="8lvf_iu71T8"
            title="Wsparcie działu handlowego - lejek sprzedaży" />
          </div>
        </div>
      </Container>

       <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Co to jest CRM?</strong>
            </p>
            <p>
              Czym jest CRM? Jakie są jego podstawowe cechy oraz zalety? W tym
              materiale przyjrzymy się nieco bliżej systemom wspomagającym
              zarządzanie relacjami z klientami.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/co-to-jest-crm">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="xW9lbrQOO6s"
            title="CRM co to jest" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Zarządzanie pracą zespołu marketingu w&nbsp;systemie CRM
              </strong>
            </p>
            <p>
              Efektywne planowanie oraz monitorowanie pracy zespołu marketingu
              to jedno z wielu wyzwań stojących przed managerem. Sprawdź, jak
              system CRM i wykorzystywane w nim widoki kanban mogą w tym pomóc.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/zarzadzanie-zespolem-marketingu-crm"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="zGWsFKGU7qY"
            title="Zarządzanie marketingiem CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>Wdrażanie kultury pracy z CRM w&nbsp;organizacji</strong>
            </p>
            <p>
              Materiał traktujący o tym, jak przeprowadzić udane wdrożenie
              systemu CRM i&nbsp;oprzeć organizację na kulturze pracy z tym
              oprogramowaniem.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/wdrazanie-kultury-pracy-CRM"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="uUOkTv49Lqg"
            title="Kultura pracy a CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu
                –&nbsp;zadania i&nbsp;zdarzenia
              </strong>
            </p>
            <p>
              Jednymi z najważniejszych aspektów pracy z&nbsp;zespołem rozproszonym
              są sprawna komunikacja i organizacja czasu pracy. Jak planowanie
              zadań i&nbsp;monitorowanie zdarzeń w&nbsp;systemie CRM wpływa na
              powyższe?
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/zarzadzanie-zespolem-rozproszonym"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
           <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="jeKe1Ur180s"
            title="Zespół rozproszony w czasie kryzysu CRM" />
          </div>
        </div>
      </Container>
        </Stack>
  );
};

const TabCrm = ({ data }) => {
  return (
    <Stack
      css={css`
        div ~ div {
          border-top: 0px solid #e1e1e1;
        }
      `}
      align="center"
    >
    <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Dlaczego warto łączyć moduł serwisowy z&nbsp;systemem CRM?
              </strong>
            </p>
            <p>
            Wyzwaniem jest dla Ciebie współpraca Twoich zespołów – sprzedażowego 
            i&nbsp;serwisowego? Zastanawiasz się jakich narzędzi informatycznych 
            dostarczyć im, aby praca przebiegała sprawniej i&nbsp;efektywniej? 
            Połącz moduł serwisowy z&nbsp;systemem CRM i&nbsp;w&nbsp;jednym 
            rozwiązaniu zarządzaj zleceniami oraz relacjami z&nbsp;klientami. 
            Obejrzyj nasz vlog i&nbsp;dowiedz się, jakie konkretne korzyści 
            uzyskasz dzięki integracji.          
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/modul-serwisowy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="OTiwe-m4Zfw"
              title="Dlaczego WARTO łączyć CRM z systemem do obsługi ZGŁOSZEŃ?"
            />
          </div>
        </div>
      </Container>
<Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?
              </strong>
            </p>
            <p>
            Jesteś menedżerem sprzedaży? Zarządzasz zespołem handlowców? Zajmujesz się wieloma różnymi zadaniami?
            Szukasz sposobu na poprawę efektywności swojej pracy? Poznaj CRM i&nbsp;dowiedz się, jak to narzędzie
            może pomóc w&nbsp;realizacji Twoich codziennych obowiązków. Znajdź przepis na sukces.          
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/wsparcie-sprzedazy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="Tqg5oPl9jp4"
              title="JAK KORZYSTAĆ z CRM będąc managerem sprzedaży?"
            />
          </div>
        </div>
      </Container>



      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Wykorzystaj program do tworzenia ofert handlowych w&nbsp;celu poprawy swoich działań sprzedażowych
              </strong>
            </p>
            <p>
            Pracujesz jako przedstawiciel handlowy i&nbsp;chciałbyś poprawić wyniki swojej pracy? Chcesz realizować targety szybciej i&nbsp;efektywniej? 
            Wykorzystaj program do&nbsp;tworzenia ofert handlowych. Nasz doświadczony handlowiec opowie Ci dzisiaj o&nbsp;systemie
            CRM, który stosuje w&nbsp;swojej pracy. Dowiesz się o&nbsp;korzyściach, które wynikają z&nbsp;jego wdrożenia.            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/program-do-tworzenia-ofert-handlowych/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="TYql_R1ca_A"
              title="CRM dla handlowców. Jak pomoże Ci w pracy?"
            />
          </div>
        </div>
      </Container>
      
      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Dashboard managera – jeden rzut oka, by&nbsp;poznać sytuację całej firmy
              </strong>
            </p>
            <p>
             Jako manager z pewnością każdego dnia podejmujesz decyzje, od których zależy rozwój firmy. 
             Używasz do tego kryształowej kuli, czy&nbsp;też opierasz się na twardych danych i&nbsp;informacjach?
             W tym materiale dowiesz się czym jest dashboard managera, dlaczego jest tak popularny oraz jak go przygotować
             nie tracąc przy tym całego dnia.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/dashboard-managera/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="WJTMIzEs71I"
              title="Wykorzystaj dashboard managera, by natychmiast poznać sytuację w całym przedsiębiorstwie"
            />
          </div>
        </div>
      </Container>

            <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                To ułatwi pracę w Twoich projektach IT
              </strong>
            </p>
            <p>
             Poznaj narzędzia, które wspierają pracę przedsiębiorstw z branży IT. W&nbsp;tym&nbsp;odcinku przedstawiamy
              sposób na efektywne zarządzanie sprzedażą oraz&nbsp;usługami na podstawie elementów, które na co dzień
              wykorzystujemy w&nbsp;Questy.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/crm-dla-firmy-it/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}>
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="eiY7L3TRmME"
              title="To UŁATWI pracę w Twoich projektach IT"
            />
          </div>
        </div>
      </Container>

             <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Proces sprzedaży: To musisz o nim wiedzieć
              </strong>
            </p>
            <p>
              We współczesnych przedsiębiorstwach niezwykle ważną rolę odgrywają 
              narzędzia pozwalające na sprawne wsparcie procesu sprzedaży. Sprawdź, jak
              po mistrzowsku możesz zaprojektować swój własny proces sprzedaży.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/proces-sprzedazy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="_zpw8pqTHqk"
              title="Jak dobrze zaprojektować proces sprzedaży?"
            />
          </div>
        </div>
      </Container>

            <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Jak zwiększyć efektywność dzięki automatyzacji sprzedaży?
              </strong>
            </p>
            <p>
              Czujesz, że tracicie czas na wykonanie prostych i powtarzalnych
              czynności? Sprawdź, jak automatyzacja wpływa na efektywność
              zespołu sprzedaży.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/automatyzacja-sprzedazy">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="PEMvVqP7-Ao"
              title="Jak zwiększyć efektywność dzięki automatyzacji sprzedaży?"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Nieobecność pracownika – jak zapewnić ciągłość pracy?</strong>
            </p>
            <p>
             Skuteczne zastąpienie pracowników firmy podczas ich nieobecności jest współcześnie
             wyzwaniem dla wielu przedsiębiorstw. Jak się odpowiednio na to przygotować?
            </p>

            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/zastepowalnosc-pracownikow">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="yQ6BSlB2JB0"
              title="Jak przygotować się na nieobecność pracownika?"
            />
          </div>
        </div>
      </Container>


      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Jak wyznaczać cele sprzedażowe?</strong>
            </p>
            <p>
              Określanie celów sprzedażowych to trudne zadanie. Co zrobić, aby
              cel był&nbsp;motywacją? O&nbsp;planach sprzedażowych i roli systemu CRM
              dowiesz się z&nbsp;tego odcinka.
            </p>

            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/cele-sprzedazowe-smart">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="rfOKAeNxzho"
              title="Cele sprzedażowe - jak je określać?"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Narzędzia CRM online do pracy zdalnej</strong>
            </p>
            <p>
              W kolejnym odcinku z vloga z serii "System CRM w zarządzaniu"
              omawiamy temat zdalnej pracy. Poznasz w nim sposoby na radzenie
              sobie z&nbsp;problemami, jakie generuje, a także jak CRM online wspiera
              firmy w pracy na&nbsp;odległość.{" "}
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/narzedzia-crm-online">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed id="qE_DnhPfvCs" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Raportowanie sprzedaży: Excel to&nbsp;ostateczność, czyli jak
                błyskawicznie raporotwać w CRM
              </strong>
            </p>
            <p>
              Z kolejnego odcinka naszego vloga na temat zarządzania firmą w CRM
              dowiesz się, jak rozbudowane narzędzia do analizy danych wspierają
              błyskawiczne raportowanie sprzedaży.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/raportowanie-sprzedazy">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
            <div className="text-block" style={{
                padding: "30px",
              }}
          >
              <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="OvD2Rdt2Klk"
            title="Jak skutecznie raportować sprzedaż?" />
            </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Integracja CRM z systemem handlowym</strong>
            </p>
            <p>
              Tym razem na naszym vlogu o zarządzaniu firmą przy użyciu systemu
              CRM – temat integracji tego rozwiązania z programami
              handlowo-magazynowymi. Piotr Szudrzyński, Business Development
              Manager w&nbsp;Questy, przedstawia, jakie informacje przekazywane są
              pomiędzy systemami, jak często i&nbsp;co&nbsp;dobrego z&nbsp;tego wynika.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/integracja-crm-z-systemem-handlowym"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
           <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="a1S10rsTsb0"
            title="Integracja CRM z ERP" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Rozbudowa kanału sprzedaży pośredniej za&nbsp;pomocą systemu CRM
              </strong>
            </p>
            <p>
              Kolejny odcinek naszego vloga o zarządzaniu poprowadzi dla Was
              Marta Kaźmierska, Business Development Manager w Questy. Tym&nbsp;razem
              tematem materiału jest sprzedaż pośrednia oraz to, jak w jej
              organizacji oraz&nbsp;optymalizacji może wspomóc Twoją firmę system
              CRM.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/rozbudowa-kanalu-sprzedazy-posredniej"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
           <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="26Meh64YxP4"
            title="CRM w sprzedaży pośredniej" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Aplikacja CRM: ułatwiona praca w terenie</strong>
            </p>
            <p>
              Witamy w kolejnym wpisie na naszym vlogu o&nbsp;efektywnym zarządzaniu
              firmą za pomocą systemu CRM. Tym razem Wojciech Nosal, Business
              Development Manager w Questy, przedstawi Wam, jakie są opcje
              zdalnej pracy z&nbsp;naszym oprogramowaniem i jak ułatwić sobie
              korzystanie z CRM na&nbsp;urządzeniach przenośnych.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/aplikacja-crm-do-pracy-w-terenie"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed id="7eiEBXoOiNA" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Zalety elastycznego systemu CRM</strong>
            </p>
            <p>
              To już jedenasty odcinek naszego vloga o&nbsp;zarządzaniu firmą za
              pomocą systemu CRM. Tym razem Joanna Kujawska, Marketing Manager w
              Questy, pochyla się nad tematem elastyczności systemów
              wspomagających zarządzanie relacjami z klientami. Zapraszamy!
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/zalety-elastycznego-crm">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="HV3OYUaHOCk"
            title="Elastyczny CRM zalety" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Ofertowanie w CRM: jak zarządzać tym procesem?</strong>
            </p>
            <p>
              W najnowszej odsłonie naszego vloga o&nbsp;zarządzaniu firmą za pomocą
              systemu CRM, poruszamy temat ofertowania. Sprawdź, w jaki sposób
              nasz system pomaga uniknąć najczęściej występujących błędów w tym
              procesie.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/ofertowanie-crm-jak-zarzadzac"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="a_KLMaAYdRc"
            title="Ofertowanie w CRM proces" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Zarządzanie kampaniami, CRM i szanse sprzedaży</strong>
            </p>
            <p>
              Czym są kampanie sprzedaży i jak się mają do&nbsp;szans sprzedaży? Jak
              nimi zarządzać w&nbsp;systemie CRM? O kampaniach i szansach opowie tym
              razem Piotr Szudrzyński, Business Development Manager w Questy.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/zarzadzanie-kampaniami-crm-szanse-sprzedazy"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
           <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="LLvCdR5OL-I"
            title="Kampanie i szanse sprzedaży w CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>System CRM a miękka windykacja</strong>
            </p>
            <p>
              To już dziewiąty odcinek naszego vloga o&nbsp;zarządzaniu firmą za
              pomocą systemu CRM. Tym razem poruszamy temat miękkiej windykacji
              w CRM i&nbsp;tego, jak wykorzystać jego funkcje do usprawnienia
              procesów ściągania zaległych należności.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/system-crm-a-miekka-windykacja"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
             <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="w5HmaTIlNnU"
            title="Skuteczna windykacja w CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Najpopularniejszych 5 funkcji w systemach CRM dla firm – RANKING
                2020
              </strong>
            </p>
            <p>
              W kolejnym odcinku naszego vloga o&nbsp;zarządzaniu firmą za pomocą
              CRM, Tomasz Wawrzyński, Marketing Specialist w Questy, przedstawia
              ranking pięciu najpopularniejszych funkcji w systemie.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/ranking-crm-dla-firm-funkcje"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
             <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="LUioSzlrkRw"
            title="Top 5 funkcji CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>5 dobrych praktyk budowania relacji z&nbsp;klientami</strong>
            </p>
            <p>
              Budowanie relacji z klientami jest niezwykle istotnym aspektem
              pracy każdego handlowca – a przynajmniej powinno nim być. W
              kolejnym odcinku naszego vloga opowiadamy, dlaczego tak jest i jak
              w budowaniu trwałych relacji z&nbsp;klientami może pomóc system CRM.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/piec-dobrych-praktyk-budowania-relacji-z-klientami"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="UnCMKaoS5H0"
            title="Praktyki budowania relacji z klientami" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Lejek sprzedaży – wsparcie dla działu handlowego</strong>
            </p>
            <p>
              W materiale tym opowiadamy, czym jest lejek sprzedaży, jak się go
              tworzy i&nbsp;jakie korzyści może przynieść firmie. Pokazujemy
              też, jak pracować z&nbsp;lejkiem w systemie CRM.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/lejek-sprzedazy-crm">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="8lvf_iu71T8"
            title="Wsparcie działu handlowego - lejek sprzedaży" />
          </div>
        </div>
      </Container>

       <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Co to jest CRM?</strong>
            </p>
            <p>
              Czym jest CRM? Jakie są jego podstawowe cechy oraz zalety? W tym
              materiale przyjrzymy się nieco bliżej systemom wspomagającym
              zarządzanie relacjami z klientami.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/co-to-jest-crm">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
              <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="xW9lbrQOO6s"
            title="CRM co to jest" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Zarządzanie pracą zespołu marketingu w&nbsp;systemie CRM
              </strong>
            </p>
            <p>
              Efektywne planowanie oraz monitorowanie pracy zespołu marketingu
              to jedno z wielu wyzwań stojących przed managerem. Sprawdź, jak
              system CRM i wykorzystywane w nim widoki kanban mogą w tym pomóc.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/zarzadzanie-zespolem-marketingu-crm"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
              <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="zGWsFKGU7qY"
            title="Zarządzanie marketingiem CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>Wdrażanie kultury pracy z CRM w&nbsp;organizacji</strong>
            </p>
            <p>
              Materiał traktujący o tym, jak przeprowadzić udane wdrożenie
              systemu CRM i&nbsp;oprzeć organizację na kulturze pracy z tym
              oprogramowaniem.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/wdrazanie-kultury-pracy-CRM"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="uUOkTv49Lqg"
            title="Kultura pracy a CRM" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Zarządzanie rozproszonym zespołem sprzedażowym w czasie kryzysu
                –&nbsp;zadania i&nbsp;zdarzenia
              </strong>
            </p>
            <p>
              Jednymi z najważniejszych aspektów pracy z&nbsp;zespołem rozproszonym
              są sprawna komunikacja i organizacja czasu pracy. Jak planowanie
              zadań i&nbsp;monitorowanie zdarzeń w&nbsp;systemie CRM wpływa na
              powyższe?
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/zarzadzanie-zespolem-rozproszonym"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="jeKe1Ur180s"
            title="Zespół rozproszony w czasie kryzysu CRM" />
          </div>
        </div>
      </Container>

      </Stack>

      
  );
};

const TabSerwis = ({ data }) => {
  return (
  <Stack
      css={css`
        div ~ div {
          border-top: 0px solid #e1e1e1;
        }
      `}
      align="center"
    >
<Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
               Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta
              </strong>
            </p>
            <p>
           Realizujesz działania w&nbsp;terenie? Doskwiera Ci ciągły brak aktualnych 
           i&nbsp;szczegółowych informacji o&nbsp;pracach koniecznych do wykonania? Chcesz 
           działać szybciej i&nbsp;efektywniej? Może to czas na wdrożenie funkcjonalnego 
           narzędzia informatycznego? Poznaj program do serwisu z&nbsp;wbudowaną aplikacją 
           do obsługi zleceń i&nbsp;ciesz się automatyzacją pracy! 
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/aplikacja-do-obslugi-zlecen/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="qHrBm6zsZ24"
              title="Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta"
            />
          </div>
        </div>
      </Container>
      
    <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Automatyczny raport pracy serwisowej. Zarządzaj serwisem w&nbsp;jednym narzędziu.
              </strong>
            </p>
            <p>
            Chcesz monitorować efektywność Twojego zespołu i&nbsp;prowadzonych przez 
            niego działań? Nie chcesz poświęcać zbyt dużej ilości czasu na proces 
            raportowania? Potrzebujesz narzędzia, które w kompleksowy sposób wesprze 
            Twoją firmę? Zapoznaj się z&nbsp;naszym materiałem i&nbsp;sprawdź, w&nbsp;jaki 
            sposób może pomóc Ci w&nbsp;tym wszystkim program do serwisu.
        
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/raport-pracy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="cDEwQhAXk58"
              title="Raportowanie pracy w programie serwisowym. Jak usprawni Twoją pracę?"
            />
          </div>
        </div>
      </Container>
      
<Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}>
              <strong>
                Dlaczego warto łączyć moduł serwisowy z&nbsp;systemem CRM?
              </strong>
            </p>
            <p>
            Wyzwaniem jest dla Ciebie współpraca Twoich zespołów – sprzedażowego 
            i&nbsp;serwisowego? Zastanawiasz się jakich narzędzi informatycznych 
            dostarczyć im, aby praca przebiegała sprawniej i&nbsp;efektywniej? 
            Połącz moduł serwisowy z&nbsp;systemem CRM i&nbsp;w&nbsp;jednym 
            rozwiązaniu zarządzaj zleceniami oraz relacjami z&nbsp;klientami. 
            Obejrzyj nasz vlog i&nbsp;dowiedz się, jakie konkretne korzyści 
            uzyskasz dzięki integracji.          
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/modul-serwisowy/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="OTiwe-m4Zfw"
              title="Dlaczego WARTO łączyć CRM z systemem do obsługi ZGŁOSZEŃ?"
            />
          </div>
        </div>
      </Container>

<Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Integracja systemów ERP z&nbsp;programem do obsługi zleceń
              </strong>
            </p>
            <p>
              Zarządzanie zleceniami to Twoja codzienność? Posiadasz już ERP,
              a&nbsp;teraz poszukujesz programu do obsługi procesów serwisowych? 
              Dowiedz się dlaczego integracja systemu handlowo-magazynowego
              z&nbsp;programem serwisowym jest tak istotna i&nbsp;co możesz dzięki niej 
              zyskać.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/integracja-systemow-erp/"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="UdfDXz3yRiY"
            title="Integracja programu do OBSŁUGI SERWISU z systemem ERP" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Obsługa reklamacji online – droga do efektywności
              </strong>
            </p>
            <p>
              Zajmujesz się reklamacjami i&nbsp;chciałbyś ulepszyć prowadzone działania?
              Zastanawiasz się na rozwiązaniami informatycznymi, które mogłyby wspomóc Cię 
              w&nbsp;pracy? Zapoznaj się z&nbsp;materiałem i&nbsp;poznaj system do obsługi reklamacji online.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/obsluga-reklamacji-online/"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="WKUJ_7FBVtU"
            title="Skuteczna obsługa reklamacji" />
          </div>
        </div>
      </Container>

        <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Jak zarządzać przeglądami cyklicznymi</strong>
            </p>
            <p>
              Zapraszamy na pierwszy odcinek serii "Zarządzanie serwisem online"
              – vloga, w&nbsp;którym wyjaśniamy, jak program do serwisu może wspomóc
              organizację pracy serwisu. W&nbsp;tym materiale Marta Kaźmierska,
              Business Development Manager w Questy, wyjaśnia jak ułatwić
              zarządzanie cyklicznymi zleceniami w&nbsp;serwisie.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/przeglady-cykliczne/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="TjlbbeCC6Xo"
            title="Jak zarządzać przeglądami cyklicznymi w serwisie" />
          </div>
        </div>
      </Container>

            <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                Jak praca w systemie do obsługi zleceń usprawnia działanie
                firmy?
              </strong>
            </p>
            <p>
              Dlaczego obsługa zleceń jest prostsza przy wykorzystaniu
              dedykowanych do tego rozwiązań? W jaki sposób program do serwisu
              wspiera kierownikow i&nbsp;serwisantów w&nbsp;codziennej pracy? Obejrzyj
              materiał, aby&nbsp;poznać odpowiedzi.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/system-do-obslugi-zgłoszen-serwisowych/"
              >
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="Pdvju_IiM2Y"
            title="CRM do obsługi zgłoszeń serwisowych" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Zarządzaj historią urządzeń swoich klientów</strong>
            </p>
            <p>
              Trzeci odcinek z serii "Zarządzanie serwisem online" prezentuje
              Aleksandra Sobczyńska. Dowiesz się, jak obniżyć koszty obsługi
              zleceń, poprzez sprawne zarządzanie historią serwisowanych
              urządzeń Twoich klientów.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/baza-urzadzen/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="BweIoQaKSNg"
            title="Historia urządzeń w serwisie" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>7 problemów Twojego serwisu – jak je rozwiązać?</strong>
            </p>
            <p>
              Drugi odcinek naszej serii "Zarządzanie serwisem online" prowadzi
              Wojciech Nosal, Business Development Manager w Questy. Dowiesz się
              między innymi:
              <ul>
                <li>jak uporządkować pracę serwisu</li>
                <li>jak zautomatyzować procesy</li>
                <li>jak usprawnić komunikację w serwisie</li>
              </ul>
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/7-wyzwan-w-serwisie/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="BNmPHQuxvA0"
            title="Główne problemy serwisu - jak je rozwiązać?" />
          </div>
        </div>
      </Container>

      </Stack>
  );
};
const TabB2B = ({ data }) => {
  return (
    <Stack
      css={css`
        div ~ div {
          border-top: 0px solid #e1e1e1;
        }
      `}
      align="center"
    >
    <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
      <strong>
              Wdrożenie panelu B2B kluczem do usprawnienia procesu sprzedaży
              </strong>
            </p>
            <p>
             Zastanawiasz się nad wdrożeniem panelu B2B, ale masz wątpliwości?
             Opowiemy Ci dzisiaj o&nbsp;zaletach tego rozwiązania oraz pokażemy, 
             że&nbsp;panel B2B jest narzędziem, które pomoże Ci jednocześnie budować 
             relacje z&nbsp;klientami i&nbsp;usprawniać proces sprzedażowy.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/wdrozenie-panelu-b2b/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="MErq_-FEF7w"
              title="Korzyści z wdrożenia panelu B2B - po co wykorzystywać platformę?"
            />
          </div>
        </div>
      </Container>

<Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
             
              <strong>
               Promowanie produktów w platformie sprzedażowej B2B
              </strong>
            </p>
            <p>
             Promowanie produktów w platformie sprzedażowej B2B jest nie tylko świetnym sposobem
             na zwiększenie sprzedaży, ale też pozwala skutecznie budować relacje biznesowe z&nbsp;kontrahentami.
              Zapoznaj się z&nbsp;tym materiałem i poznaj skuteczne metody wyróżniania Twoich produktów, które przełożą
              się na relacje Twojej firmy z klientami B2B.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/promowanie-b2b/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
              poster="maxresdefault"
              id="5vQdn_M_SBU"
              title="Niezawodne sposoby na promocję produktów w platformie sprzedażowej B2B"
            />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Integracja B2B z ERP: dlaczego warto?</strong>
            </p>
            <p>
              Zapraszamy na trzeci odcinek serii "Sprzedaż online z platformą
              B2B" – vloga, na którym wyjaśniamy, jak odnieść sukces w hurtowej
              sprzedaży online. W tym materiale Piotr Szudrzyński, Business
              Development Manager w Questy, wyjaśnia dlaczego warto zintegrować
              platformę B2B z Twoim systemem ERP.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/b2b-dla-erp/">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="sS8OF33PvTU"
            title="Integracja B2B z ERP" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              <strong>5 etapów wdrożenia systemu B2B w&nbsp;organizacji</strong>
            </p>
            <p>
              Wdrożenie platformy B2B można podzielić na kilka etapów. Marta
              Kaźmierska, BDM Questy, omówi pięć kluczowych kroków skutecznego
              wdrożenia platformy sprzedażowej.
              <ol>
                <li>Wizja biznesowa</li>
                <li>Przygotowanie platformy</li>
                <li>Szkolenie pracowników</li>
                <li>Wdrożenie klienta</li>
                <li>Analiza, testowanie i doskonalenie</li>
              </ol>
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/wdrozenie-platformy-b2b">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="HnRLj0RH4DI"
            title="Wdrożenie B2B 5 kroków" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
           <p style={{
            paddingTop: 35,
          }}
        >
              <strong>
                System B2B – przeznaczenie i&nbsp;najważniejsze funkcje
              </strong>
            </p>
            <p>
              Zapraszamy na drugą odsłonę vloga "Sprzedaż online z platformą
              B2B" – vloga, na którym wyjaśniamy, jak odnieść sukces w hurtowej
              sprzedaży online. W niniejszym materiale Aleksandra Sobczyńska,
              Business Development Coordinator w Questy, opowie o&nbsp;tym, w jakich
              firmach systemy B2B spisują się najlepiej. Przedstawi też listę
              dziesięciu najważniejszych funkcji w tego typu oprogramowaniu.
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/system-b2b-funkcje">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
            <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="FjqEsztUNaA"
            title="Przeznaczenie systemu B2B i jego funkcje" />
          </div>
        </div>
      </Container>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
            paddingTop: 35,
          }}
        >
              <strong>Czym jest platforma B2B?</strong>
            </p>
            <p>
              Witamy serdecznie w pierwszym materiale na naszym nowym vlogu.
              Będziemy się na nim zajmować tematyką sprzedaży online. Na&nbsp;pierwszy 
              ogień idzie rzecz zupełnie podstawowa - odpowiedź na
              pytanie “Czym jest platforma B2B?” Oprócz wyjaśnienia tej kwestii,
              powiemy też kilka słów o&nbsp;tym, co dobrze jest wiedzieć przed
              inwestycją w jedną z takich platform.{" "}
            </p>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link className={styles.startNow2} to="/czym-jest-platforma-b2b">
                Przeczytaj więcej
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                padding: "30px",
              }}
          >
           <LiteYouTubeEmbed
            poster="maxresdefault" 
            id="P04WxsFvuE8"
            title="Platforma B2B co to jest?" />
          </div>
        </div>
      </Container>
      </Stack>

  );
};


export default Blog;
